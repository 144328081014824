import axios from 'axios'

const models = () => [
  {
      name: "reminder",
      mutation: "SET_REMINDERS",
      stateVariable: "reminders",
  },
  {
    name: "program",
    mutation: "SET_PROGRAMS",
    stateVariable: "programs",
  },
  {
    name: "category",
    mutation: "SET_CATEGORIES",
    stateVariable: "categories",
  },
  {
      name: "journee",
      mutation: "SET_JOURNEES",
      stateVariable: "journees",
  },
  {
      name: "goal",
      mutation: "SET_GOALS",
      stateVariable: "goals",
  },
  {
      name: "mood",
      mutation: "SET_MOODS",
      stateVariable: "moods",
  },
  {
      name: "project",
      mutation: "SET_PROJECTS",
      stateVariable: "projects",
  },
  {
      name: "note",
      mutation: "SET_NOTES",
      stateVariable: "notes",
  },
  {
      name: "person",
      mutation: "SET_PERSONS",
      stateVariable: "persons",
  },
  {
      name: "place",
      mutation: "SET_PLACES",
      stateVariable: "places",
  },
  {
      name: "exercise",
      mutation: "SET_EXERCISES",
      stateVariable: "exercises",
  },
  {
      name: "value",
      mutation: "SET_VALUES",
      stateVariable: "values",
  },
  {
      name: "mission",
      mutation: "SET_MISSION",
      stateVariable: "mission",
  },
  {
      name: "belief",
      mutation: "SET_BELIEFS",
      stateVariable: "beliefs",
  },
  {
      name: "workout",
      mutation: "SET_WORKOUTS",
      stateVariable: "workouts",
  },
  {
      name: "book",
      mutation: "SET_BOOKS",
      stateVariable: "book",
  },
  {
      name: "event",
      mutation: "SET_EVENTS",
      stateVariable: "events",
  },
  {
    name: "activity",
    mutation: "SET_ACTIVITIES",
    stateVariable: "activities"
  },
  {
    name: "interest",
    mutation: "SET_INTERESTS",
    stateVariable: "interests"
  },
  {
    name: "plannedactivity",
    mutation: "SET_PLANNED_ACTIVITIES",
    stateVariable: "plannedActivities"
  },
  {
    name: "ritual",
    mutation: "SET_RITUALS",
    stateVariable: "rituals"
  },
  {
    name: "workout_session",
    mutation: "SET_WORKOUT_SESSIONS",
    stateVariable: "workoutSessions"
  },
  {
    name: "life_plan",
    mutation: "SET_LIFE_PLANS",
    stateVariable: "lifePlans"
  },
  {
    name: "journal_entry",
    mutation: "SET_JOURNAL_ENTRIES",
    stateVariable: "journal_entries"
  },
  {
    name: "sleep_score",
    mutation: "SET_SLEEP_SCORES",
    stateVariable: "sleep_scores"
  }

];

const getDefaultState = () => {
  return {
    board: 0,
    onboarded: true,
    mission: {},
    categories: [],
    activities: [],
    muscles: [],
    resolutions: [],
    simpleModelData: [],
    plannedActivities: [],
    lifePlans: [],
    boards: [],
    books: [],
    notes: [],
    workoutSessions: [],
    persons: [],
    moods: [],
    goals: [],
    journees: [],
    workouts: [],
    projects: [],
    beliefs: [],
    values: [],
    rituals: [],
    reminders: [],
    places: [],
    notes: [],
    exercises: [],
    journal_entries: [],
    sleepScores: [],
    interests: [],
    note: {},
    journalEntry: {}
  }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        board(state){
            return state.board
        },
        onboarded(state){
          return state.onboarded
        }
    },
    actions: {

      fetchSimpleModel({commit, state}, modelObj) {

        axios.post('/api/simple_model/' + modelObj.endpoint,{board: state.board, model: modelObj.name}).then(result => {

          if(result.data && result.data.data) {
            if(modelObj.mutation) {
              commit(modelObj.mutation, result.data.data);
            } else {
              commit('SET_SIMPLE_MODEL_DATA', result.data.data);
            }
            
          }

          if(result && result.data.success == false && result.data.error) {
            if(modelObj.mutation) {
              commit(modelObj.mutation, []);
            } else {
              commit('SET_SIMPLE_MODEL_DATA', []);
            }
            
          }

          if(result && result.hasOwnProperty('status')) {

            let status = result.status;

            if(status == 401) {
              dispatch('resetDataStore');
              dispatch('auth/logout', null, {root: true});
            }
          }
          
        }).catch(error => {
          console.log("Error fetching data!");
          console.log(error.response.status);
        });
      },
      updateSimpleModel({commit, state}, modelObj) {

        return axios.post('/api/simple_model/update',{board: state.board, model: modelObj.name, responses: modelObj.responses}).then(result => {
          if(result.data && result.data[modelObj.name]) {
            return true;
          } else {
            return false;
          }
          
        }).catch(error => {
          console.log(error.response.status);
          return false;
        });
      },
      fetch({commit, dispatch, state}, modelObj) {

        let Model = models().find(x => x.name == modelObj.name);

        if(!Model) {
          alert("Unable to find model: " + modelObj.name);
        }

        axios.post('/api/' + Model.name + '/' + modelObj.endpoint,{board: state.board, params: modelObj.params}).then(result => {

          if(result.data && result.data[Model.stateVariable]) {
            commit(Model.mutation, result.data[Model.stateVariable]);
          }

          if(result.data && result.data.success == false) {
            commit(Model.mutation, []);
          }

          if(result && result.hasOwnProperty('status')) {

            let status = result.status;

            if(status == 401) {
              dispatch('resetDataStore');
              dispatch('auth/logout', null, {root: true});
            }
          }
          
        }).catch(error => {
          console.log("Error fetching data!");
        });
      },
      setOnboarded({commit, dispatch, state}, dataObj) {

        commit('SET_ONBOARDED',dataObj.val)

        axios.post('/api/onboarded/true').then(result => {
          
        }).catch(error => {
          console.log("Error fetching data!");
        });
      },
      async addBoard({commit,dispatch}, name) {
        try {
          const { data } = await axios.post('/api/board/create', { name: name });
          if (data && data.success && data.board.id) {
            commit('SET_BOARD', data.board.id);
            dispatch('getBoardsForUser');
          }
        } catch ({ response: { data: data_1 } }) {
          commit('SET_BOARD', 0);
        }
      },
      getMuscles({commit,dispatch}, name) {
        return axios.post('/api/muscle/all').then(({data})=>{
          if(data && data.success && data.muscles) {
            commit('SET_MUSCLES',data.muscles)
            dispatch('getBoardsForUser');
          }
        }).catch(({response:{data}})=>{
          commit('SET_BOARD',0)
        })
      },
      getBoardsForUser({commit, dispatch}) {
        axios.get('/api/board/all').then(result => {

            if(result && result.data && result.data.boards) {
              commit('SET_BOARDS',result.data.boards)
            }

            if(result && result.hasOwnProperty('status')) {

              let status = result.status;

              if(status == 401) {
                dispatch('resetDataStore');
                dispatch('auth/logout', null, {root: true});
              }
            }
        }).catch(({response:{data}})=>{
            commit('SET_BOARDS',[])
        })
      },
      setCategories({commit, dispatch}, categories) {
        commit('SET_CATEGORIES',categories)
      },
      setNote({commit}, note) {
        commit('SET_NOTE',note);
      },
      addJournalEntry({commit}, journalEntry) {
        commit('ADD_JOURNAL_ENTRY',journalEntry);
      },
      setJournalEntry({commit}, journalEntry) {
        commit('SET_JOURNAL_ENTRY',journalEntry);
      },
      updateNote({commit}, note) {
        commit('UPDATE_NOTE_IN_NOTES', note);
      },
      updateJournalEntry({commit}, journalEntry) {
        commit('UPDATE_ENTRY_IN_JOURNAL_ENTRIES', journalEntry);
      },
      addActivityToActivities({commit}, activity) {
        commit('ADD_ACTIVITY_TO_ACTIVITIES', activity);
      },
      removeJourneeFromJournees({commit}, journeeID) {
        commit('REMOVE_JOURNEE_FROM_JOURNEE_ENTRIES', journeeID);
      },
      removeActivityFromActivities({commit}, activityID) {
        commit('REMOVE_ACTIVITY_FROM_ACTIVITIES', activityID);
      },
      removePlannedActivityFromPlannedActivities({commit}, plannedActivityID) {
        commit('REMOVE_PLANNED_ACTIVITY_FROM_PLANNED_ACTIVITIES', plannedActivityID);
      },
      updateActivityInActivities({commit}, activityEntry) {
        commit('UPDATE_ACTIVITY_IN_ACTIVITIES', activityEntry);
      },
      clearActivities({commit}) {
        commit('SET_ACTIVITIES', []);
      },
      updateActivities({commit}, activities) {
        commit('SET_ACTIVITIES', activities);
      },
      resetDataStore({commit}) {
        commit('RESET_DATA_STORE');
      },
      changeBoard({commit}, newBoardId) {
        return axios.post('/api/board/verify',{id: newBoardId}).then(({data})=>{
            if(data && data.success) {
              commit('RESET_DATA_STORE');
              commit('SET_BOARD',data.board.id);
              return true;
            }
        }).catch(({response:{data}})=>{
            commit('SET_BOARD',0)
            return false;
        })
      },
    },
    mutations: {
      RESET_DATA_STORE(state) {
        Object.assign(state, getDefaultState());
      },
      SET_SIMPLE_MODEL_DATA(state, simpleModelData) {
        state.simpleModelData = simpleModelData;
      },
      SET_ONBOARDED(state, value) {
        state.onboarded = value
      },
      SET_BOARD (state, value) {
        state.board = value
      },
      SET_SLEEP_SCORES(state, value) {
        state.sleep_scores = value
      },
      SET_MUSCLES (state, value) {
        state.muscles = value;
      },
      SET_CATEGORIES (state, value) {
        state.categories = value;
      },
      SET_BOARDS (state, value) {
        state.boards = value
      },
      SET_MISSION (state, mission) {
        state.mission = mission
      },
      SET_LIFE_PLANS (state, lifePlans) {
        state.lifePlans = lifePlans
      },
      SET_WORKOUTS (state, workouts) {
        state.workouts = workouts
      },
      SET_PLANNED_ACTIVITIES(state, plannedActivities) {
        state.plannedActivities = plannedActivities;
      },
      SET_EVENTS (state, events) {
        state.events = events
      },
      SET_PROJECTS (state, projects) {
        state.projects = projects
      },
      SET_BOOKS (state, books) {
        state.books = books
      },
      SET_PERSONS(state, persons) {
        state.persons = persons;
      },
      SET_WORKOUT_SESSIONS(state, workoutSessions) {
        state.workoutSessions = workoutSessions;
      },
      SET_INTERESTS(state, interests) {
        state.interests = interests;
      },
      SET_RITUALS(state, rituals) {
        state.rituals = rituals;
      },
      SET_JOURNAL_ENTRIES(state, journalEntries) {
        state.journal_entries = journalEntries;
      },
      ADD_JOURNAL_ENTRY(state, journalEntry) {
        state.journal_entries.push(journalEntry);
      },
      SET_ACTIVITIES(state, activities) {
        state.activities = activities;
      },
      SET_EXERCISES(state, exercises) {
        state.exercises = exercises;
      },
      UPDATE_NOTE_IN_NOTES(state, note) {
        state.notes = [...state.notes.filter(el => el.id !== note.id),note]
      },
      UPDATE_STEP_IN_WORKOUT_SESSION(state, workoutSessionStep) {
        state.workout_session = [...state.workout_session.steps.filter(el => el.id !== workoutSessionStep.id), workoutSessionStep];
      },
      UPDATE_ENTRY_IN_JOURNAL_ENTRIES(state, journalEntry) {
        state.journal_entries = [...state.journal_entries.filter(el => el.id !== journalEntry.id),journalEntry]
      },
      ADD_ACTIVITY_TO_ACTIVITIES(state, activity) {
        state.activities.push(activity);
      },
      UPDATE_ACTIVITY_IN_ACTIVITIES(state, activityEntry) {
        state.activities = [...state.activities.filter(el => el.id !== activityEntry.id),activityEntry]
      },
      REMOVE_JOURNEE_FROM_JOURNEE_ENTRIES(state, journeeID) {
        state.journees = [...state.journees.filter(el => el.id !== journeeID)]
      },
      REMOVE_ACTIVITY_FROM_ACTIVITIES(state, activityID) {
        state.activities = [...state.activities.filter(el => el.id !== activityID)]
      },
      REMOVE_PLANNED_ACTIVITY_FROM_PLANNED_ACTIVITIES(state, plannedActivityID) {
        state.plannedActivities = [...state.plannedActivities.filter(el => el.id !== plannedActivityID)]
      },
      SET_NOTES(state, notes) {
        state.notes = notes;
      },
      SET_RESOLUTIONS(state, resolutions) {
        state.resolutions = resolutions;
      },
      SET_NOTE(state, note) {
        state.note = note;
      },
      SET_JOURNAL_ENTRY(state, journalEntry) {
        state.journalEntry = journalEntry;
      },
      SET_PLACES(state, places) {
        state.places = places;
      },
      SET_MOODS(state, moods) {
        state.moods = moods;
      },
      SET_REMINDERS(state, reminders) {
        state.reminders = reminders;
      },
      SET_RITUALS(state, rituals) {
        state.rituals = rituals;
      },
      SET_VALUES(state, values) {
        state.values = values;
      },
      SET_BELIEFS(state, beliefs) {
        state.beliefs = beliefs;
      },
      SET_JOURNEES(state, journees) {
        state.journees = journees;
      },
      SET_GOALS(state, goals) {
        state.goals = goals;
      },
      SHOW_SIDEBAR(state, value) {
        state.showSidebar = value;
      }
    }
}