export default {
  data() {
    return {
    }
  },
  methods: {
    arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    },
    byteArraytoHexString(arrayBuffer) {

      //I know this should be moved outside the function.
      const byteToHex = [];

      for (let n = 0; n <= 0xff; ++n)
      {
          const hexOctet = n.toString(16).padStart(2, "0");
          byteToHex.push(hexOctet);
      }

      const buff = new Uint8Array(arrayBuffer);
      const hexOctets = []; // new Array(buff.length) is even faster (preallocates necessary array size), then use hexOctets[i] instead of .push()

      for (let i = 0; i < buff.length; ++i)
          hexOctets.push(byteToHex[buff[i]]);

      return hexOctets.join("");
    },
    hexStringToArrayBuffer(hexString) {
        // remove the leading 0x
        hexString = hexString.replace(/^0x/, '');
        
        // ensure even number of characters
        if (hexString.length % 2 != 0) {
            console.log('WARNING: expecting an even number of characters in the hexString');
        }
        
        // check for some non-hex characters
        var bad = hexString.match(/[G-Z\s]/i);
        if (bad) {
            console.log('WARNING: found non-hex characters', bad);    
        }
        
        // split the string into pairs of octets
        var pairs = hexString.match(/[\dA-F]{2}/gi);
        
        // convert the octets to integers
        var integers = pairs.map(function(s) {
            return parseInt(s, 16);
        });
        
        var array = new Uint8Array(integers);

        return array.buffer;
    },


    async ctrEncrypt(data, userID) {
      let deviceUUID = await this.getUsersDeviceUUID(userID);

      let cipherText = "Unknown.";

      if(deviceUUID) {
        let key = await this.getUsersCTRKey(userID);

        if(key && key.key) {

          let encodedData = new TextEncoder().encode(data);

          let counter = window.crypto.getRandomValues(new Uint8Array(16));

          try {
            cipherText = await window.crypto.subtle.encrypt(
              {
                name: "AES-CTR",
                counter,
                length: 64
              },
              key.key,
              encodedData
            );
          } catch(e) {
            console.log("Failed to perform encryption!");
            console.log(e);
          }

          let hexCipherText = this.byteArraytoHexString(cipherText);

          return {
              cipherTextInHex: hexCipherText,
              counterinHex: this.byteArraytoHexString(counter)
          };

        } else {
          let key = await this.generateCTREncryptionKey(userID);

          if(key) {
            await this.storeCTREncryptionKeyForUser(userID, key);
            alert("Successfully stored and generating key, trying again!");
            await this.ctrEncrypt(data, userID);
            alert("Done!");
          } else {
            alert("Failed to generate CTR Encrypt key!");
          }
        }
      }
    },
    generateSalt(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    xorEncryptDecrypt(inputHex, keyHex) {
        let outputHex = '';
        for (let i = 0; i < inputHex.length; i+=2) {
            const inputCharCode = parseInt(inputHex.substr(i, 2), 16);
            const keyCharCode = parseInt(keyHex.substr(i % keyHex.length, 2), 16);
            const xorCharCode = inputCharCode ^ keyCharCode;
            outputHex += xorCharCode.toString(16).padStart(2, '0');
        }
        return outputHex;
    },
    arrBufferToHexString(arrayBuffer) {
      const byteArray = new Uint8Array(arrayBuffer);
      let hexString = '';
      byteArray.forEach(byte => {
          hexString += byte.toString(16).padStart(2, '0');
      });
      return hexString;
    },
    async encryptKeyWithPassphrase(userKeyHex, pin) {
      const salt = this.generateSalt(16);
      const hashedPin = await this.hashPin(pin, salt);
      const hashedPinInHex = this.arrBufferToHexString(hashedPin);
      const encryptedKeyHex = this.xorEncryptDecrypt(userKeyHex, hashedPinInHex);
      
      return {
          encryptedKey: encryptedKeyHex,
          salt
      };
    },
    async decryptKeyWithPassphrase(pin, salt, encryptedKey) {
      const hashedPin = await this.hashPin(pin, salt);
      const hashedPinInHex = this.arrBufferToHexString(hashedPin);
      const decryptedKeyHex = this.xorEncryptDecrypt(encryptedKey, hashedPinInHex);
  
      return decryptedKeyHex;
    },
  
    async hashPin(pin, salt) {
      const encoder = new TextEncoder();
      const data = encoder.encode(pin + salt);
      const hash = await window.crypto.subtle.digest('SHA-256', data);

      return hash;
    },

    async getUsersDeviceUUID(userID) {
      let db = await this.openIndexedDB();

      if(db) {
        try {
          return await this.getDeviceUUIDFromIndexDBForUser(userID, db, 'UserDevices');
        } catch(e) {
          console.log("Failed to get deviceUUID!");
          return false;
        }
  
      } else {
        alert("Unable to get Database!");
      }
    },
    async importCTREncryptionKey(rawKey) {
      return window.crypto.subtle.importKey(
        "raw",
        this.hexStringToArrayBuffer(rawKey),
        "AES-CTR",
        true,
        ["encrypt", "decrypt"]
      );
    },
    async importEncryptionKey(rawKey) {
      return window.crypto.subtle.importKey(
        "raw",
        Uint8Array.from(atob(rawKey), (c) => c.charCodeAt(0)),
        "AES-GCM",
        true,
        ["encrypt", "decrypt"]
      );
    },
    async exportUsersCTRKey(userID) {
      let db = await this.openIndexedDB();
      let key;

      if(db) {
        
        try {
          key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');
        } catch(e) {
          console.log("Failed to get key!");
        }

        if(key && key.key) {
          return await window.crypto.subtle.exportKey(
            "raw",
            key.key
          );
        } else {
          return false;
        }

      } else {
        alert("Unable to get Database!");
      }  
    },
    async getUsersCTRKey(userID) {

      let db = await this.openIndexedDB();
      let key;

      if(db) {
        
        try {
          return await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');
        } catch(e) {
          console.log("Failed to get key!");
        }

      } else {
        alert("Unable to get Database!");
      }      
    },
    async getUsersEncryptionKey(userID) {

      let db = await this.openIndexedDB();
      let key;

      if(db) {
        
        try {
          key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeys');
        } catch(e) {
          console.log("Failed to get key!");
        }
        

        if(key && key.key) {
          return await window.crypto.subtle.exportKey(
            "raw",
            key.key
          );
        } else {
          return false;
        }
      } else {
        alert("Unable to get Database!");
      }      
    },
    async removeAllDevicesForUser(userID) {
      let db = await this.openIndexedDB();
    
      if(db) {
        console.log("DB Open Removing User Devices");
        return await this.removeUserDevices(userID, db, 'UserDevices');
      } else {
        console.log("Failed to open IndexedDB!");
      }
    },

    async deleteEncryptionKeyForUser(userID) {

      let db = await this.openIndexedDB();

      if(db) {
        return await this.deleteKey(userID, db, 'UserKeysCTR').then(response => { 
          return true;
        })
        .catch(error => {
          console.log(error)
          return false;
        })
      } else {
        return false;
      }
    },


    async storeCTREncryptionKeyForUser(userID, key) {

      let db = await this.openIndexedDB();

      if(db) {
        return await this.storeKey(key, userID, db, 'UserKeysCTR').then(response => { 
          return true;
        })
        .catch(error => {
          console.log(error)
          return false;
        })
      } else {
        return false;
      }
    },

    async storeEncryptionKeyForUser(userID, key) {

      let db = await this.openIndexedDB();

      if(db) {
        return await this.storeKey(key, userID, db, 'UserKeys').then(response => { 
          return true;
        })
        .catch(error => {
          //console.error(error)
          return false;
        })
      } else {
        return false;
      }
    },

    async generateCTREncryptionKey() {

      return await window.crypto.subtle.generateKey(
        {
          name: "AES-CTR",
          length: 256
        },
        true,
        ["encrypt", "decrypt"]
      );
    },

    async generateEncryptionKey() {

      return await window.crypto.subtle.generateKey(
        {
          name: "AES-GCM",
          length: 256
        },
        true,
        ["encrypt", "decrypt"]
      );
    },

    async storeDeviceUUIDForUser(userID, deviceUUID) {
      let db = await this.openIndexedDB();

      if(db) {

        return await this.storeDeviceUUID(userID, deviceUUID, db, 'UserDevices');

      } else {
        console.log("Failed to open IndexedDB!");
      }

    },
    async verifyUserHasKey(userID) {
      let db = await this.openIndexedDB();

      if(db) {

        let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');
        
        if(key) {
          return true;
        }
      }

      return false;
    },
    async userHasCTRKey(userID) {
      let db = await this.openIndexedDB();

      if(db) {

        let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');
        
        if(key) {
          return true;
        }
      }

      return false;
    },

    async verifyUserHasKeyAndDeviceUUID(userID) {
      let db = await this.openIndexedDB();

      if(db) {

        let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');

        let deviceUUID = await this.getDeviceUUIDFromIndexDBForUser(userID, db, 'UserDevices');

        if(key && deviceUUID) {
          return deviceUUID;
        }
      }

      return false;
    },

    async generateAndStoreEncryptionKeyForUser(userID) {

      let db = await this.openIndexedDB();

      if(db) {

        let key = await this.generateCTREncryptionKey();

        if(key) {
          return await this.storeKey(key, userID, db, 'UserKeysCTR').then(response => { 
            return true;
          })
          .catch(error => {
            //console.error(error)
            return false;
          })
        } else {
          return false;
        }

      } else {
        console.log("Failed to open IndexedDB!");
      }

      

    },

    async encryptData(protectedBy, data, userID) {

      let db = await this.openIndexedDB();

      if(db) {

        let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeys');

        if(key) {
          return await this.encrypt(protectedBy, key.key, data)
        } else {
          alert("No Key to perform encryption!");
        }
      } else {
        alert("Error creating IndexedDB");
      }

      
      
    },
    getIv(protectedBy) {
      return this.pbkdf2(protectedBy, (new Date().getTime().toString()), 1, 12, 'SHA-256')
    },
    async openIndexedDB() {
      return new Promise((resolve, reject) => {
        // This works on all devices/browsers, and uses IndexedDBShim as a final fallback 
        var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        
        // Open (or create) the database
        var open = indexedDB.open("JourneeDB", 2);
        open.onsuccess = _ => resolve(open.result);
        open.onerror = event => reject(event);
        open.onupgradeneeded = function() {
            var db = open.result;

            if(!db.objectStoreNames.contains('UserDevices')) {
              db.createObjectStore("UserDevices", {keyPath: "user"});
            }

            if(!db.objectStoreNames.contains('UserKeys')) {
              db.createObjectStore("UserKeys", {keyPath: "user"});
            }

            if(!db.objectStoreNames.contains('UserKeysCTR')) {
              db.createObjectStore("UserKeysCTR", {keyPath: "user"});
            }

            resolve(open.result);
        };
      });
    },
    async decryptCTRData(protectedBy, data, userID) {

      let db = await this.openIndexedDB();

      let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeysCTR');

      if(key) {
        try {

          let counter = this.hexStringToArrayBuffer(protectedBy);

          let ciphertext = this.hexStringToArrayBuffer(data);

          let decryptedArray = await window.crypto.subtle.decrypt(
            {
              name: "AES-CTR",
              counter,
              length: 64
            },
            key.key,
            ciphertext
          );

          return new TextDecoder("utf-8").decode(new Uint8Array(decryptedArray));

        } catch(e) {
          console.log("Error performing decryption");
        }
      } else {
        alert("No Key to perform decryption");
      }      
    },
    async decryptData(protectedBy, data, userID) {

      let db = await this.openIndexedDB();

      let key = await this.getUsersKeyFromIndexDB(userID, db, 'UserKeys');

      if(key) {
        try {
          return await this.decrypt(protectedBy, key.key, data);
        } catch(e) {
          console.log("Error performing decryption");
        }
      } else {
        alert("No Key to perform decryption");
      }      
    },
    async storeDeviceUUID(userID, deviceUUID, db, storeName) {

      return new Promise((resolve, reject) => {
        let result;
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve(result);
        tx.onerror = event => reject(event.target.error);
        const store = tx.objectStore(storeName);
        const request = store.add({user: userID, uuid: deviceUUID});
        request.onsuccess = _ => result = request.result;
      });
    },
    async deleteKey(userID, db, storeName) {
      return new Promise((resolve, reject) => {
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve("Key deleted successfully!");
        tx.onerror = event => reject(event.target.error);
        
        const store = tx.objectStore(storeName);
        const request = store.delete(userID);
        
        request.onsuccess = _ => resolve("Key deleted successfully!");
        
        request.onerror = _ => {
            reject("Failed to delete key!");
        };
      });
    },
    async removeUserDevices(userID, db, storeName) {

      console.log("Remove user devices for user:", userID);

      console.log("Store Name: " + storeName);

      return new Promise((resolve, reject) => {
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve();
        tx.onerror = event => reject(event.target.error);
        
        const store = tx.objectStore(storeName);
        const request = store.delete(userID);
        
        request.onsuccess = _ => resolve("Device deleted successfully!");
        
        request.onerror = _ => {
            reject("Failed to delete device!");
        };
      });
    },
    async storeKey(key, userID, db, storeName) {

      return new Promise((resolve, reject) => {
        let result;
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve(result);
        tx.onerror = event => reject(event.target.error);
        const store = tx.objectStore(storeName);
        const request = store.add({user: userID, key: key});
        request.onsuccess = _ => result = request.result;
        request.onerror = function() {
          console.log("Failed to store key!");
        };
      });
    },

    async getDeviceUUIDFromIndexDBForUser(userID, db, storeName) {
      return new Promise((resolve, reject) => {
        let result;
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve(result);
        tx.onerror = event => reject(event.target.error);
        const store = tx.objectStore(storeName);
        const request = store.get(userID); //Device UUID is 2
        request.onsuccess = _ => result = request.result;
        request.onerror = event => reject(event);
      });
    },
    async getUsersKeyFromIndexDB(userID, db, storeName) {

      return new Promise((resolve, reject) => {
        let result;
        const tx = db.transaction(storeName, 'readwrite');
        tx.oncomplete = _ => resolve(result);
        tx.onerror = event => reject(event.target.error);
        const store = tx.objectStore(storeName);
        const request = store.get(userID);
        request.onsuccess = _ => result = request.result;
        request.onerror = event => reject(event);
      });
    },
    async pbkdf2(message, salt, iterations, keyLen, algorithm) {
      const msgBuffer = new TextEncoder('utf-8').encode(message)
      const msgUint8Array = new Uint8Array(msgBuffer)
      const saltBuffer = new TextEncoder('utf-8').encode(salt)
      const saltUint8Array = new Uint8Array(saltBuffer)

      const key = await crypto.subtle.importKey('raw', msgUint8Array, {
        name: 'PBKDF2'
      }, false, ['deriveBits'])

      const buffer = await crypto.subtle.deriveBits({
        name: 'PBKDF2',
        salt: saltUint8Array,
        iterations: iterations,
        hash: algorithm
      }, key, keyLen * 8)

      return this.base64Encode(new Uint8Array(buffer))
    },
    async encrypt(iv, key, data) {

      const msgBuffer = new TextEncoder('utf-8').encode(iv)
      const initializationVector = new Uint8Array(msgBuffer)

      let cipherText = await window.crypto.subtle.encrypt(
        { name: "AES-GCM", iv: initializationVector },
        key,
        new TextEncoder('utf-8').encode(data)
      );

      return cipherText;
    },
    base64Encode(u8) {
      return btoa(String.fromCharCode.apply(null, u8))
    },
    base64Decode(str) {
      return new Uint8Array(atob(str).split('').map(c => c.charCodeAt(0)))
    },
    async decrypt(iv, key, cipherText) {

      const msgBuffer = new TextEncoder('utf-8').encode(iv)
      const initializationVector = new Uint8Array(msgBuffer)

      let decryptedArray = await window.crypto.subtle.decrypt(
        { name: "AES-GCM", iv: initializationVector },
        key,
        cipherText
      );

      return new TextDecoder("utf-8").decode(new Uint8Array(decryptedArray));
    }
  }
  // etc.
}