import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import store from './store'
import auth from './auth'

export default new createStore({
    plugins:[
        createPersistedState()
    ],
    modules:{
        store,
        auth
    }
})