import { createWebHistory, createRouter } from "vue-router";

import store from '../store'

const DefaultPageLayout = () => import('../components/Layouts/DefaultPageLayout.vue');

const UserPageLayout = () => import('../components/Layouts/UserPageLayout.vue');

const AdminPageLayout = () => import('../components/Layouts/AdminPageLayout.vue');

const AboutUsPage = () => import('../components/Pages/AboutUsPage.vue');

const Page404Error = () => import('../components/Pages/Page404Error.vue');

const ContactPage = () => import('../components/Pages/ContactPage.vue');

const ProgramsPage = () => import('../components/Pages/ProgramsPage.vue');

const SingleProgramPage = () => import('../components/Pages/SingleProgramPage.vue');

const LandingPage = () => import('../components/Pages/LandingPage.vue');

const TermsOfServicePage = () => import('../components/Pages/TermsOfServicePage.vue');

const AccountSettingsPage = () => import('../components/Pages/AccountSettingsPage.vue');

const PrivacyPolicyPage = () => import('../components/Pages/PrivacyPolicyPage.vue');

const PricingPage = () => import('../components/Pages/PricingPage.vue');

const UserDevicesPage = () => import('../components/Pages/UserDevicesPage.vue')

const UserDataTypesPage = () => import('../components/Pages/UserDataTypesPage.vue')

const SingleUserDataTypePage = () => import('../components/Pages/SingleUserDataTypePage.vue')

const SharedPage = () => import('../components/Pages/SharedPage.vue');

const UsersSharedPages = () => import('../components/Pages/SharedPages/All.vue');

const OnboardingPage = () => import('../components/Pages/OnboardingPage.vue');

const ExplorePage = () => import('../components/Pages/ExplorePage.vue');

const OverviewPage = () => import('../components/Pages/OverviewPage.vue');

const UserHomePage = () => import('../components/Pages/UserHomePage.vue');

const UserBoardsPage = () => import('../components/Pages/UserBoardsPage.vue');

const HowJourneeWorksPage = () => import('../components/Pages/HowJourneeWorksPage.vue');

const LoginPage = () => import('../components/Pages/LoginPage.vue');

const SignUpPage = () => import('../components/Pages/SignUpPage.vue');

const SleepPage = () => import('../components/Pages/SleepPage.vue');

const JournalPage = () => import('../components/Pages/JournalPage.vue');

const ProjectsPage = () => import('../components/Pages/ProjectsPage.vue');

const CalendarPage = () => import('../components/Pages/CalendarPage.vue');

const EventsPage = () => import('../components/Pages/EventsPage.vue');

const AddContentPage = () => import('../components/Pages/AddContentPage.vue');

const SettingGoalsPage = () => import('../components/Pages/SettingGoals.vue');

const LifePlanningPage = () => import('../components/Pages/LifePlanningPage.vue');

const SingleLifePlanPage = () => import('../components/Pages/SingleLifePlanPage.vue');

const MoodsPage = () => import('../components/Pages/MoodsPage.vue');

const RemindersPage = () => import('../components/Pages/RemindersPage.vue')

const ActivitiesPage = () => import('../components/Pages/ActivitiesPage.vue')

const ExercisesPage = () => import('../components/Pages/ExercisesPage.vue')

const WorkoutsPage = () => import('../components/Pages/WorkoutsPage.vue')

const ForgotPasswordPage = () => import('../components/Pages/ForgotPasswordPage.vue')

const UserJourneeCategories = () => import('../components/Pages/UserJourneeCategories.vue')

const BooksPage = () => import('../components/Pages/BooksPage.vue')

const PlannerPage = () => import('../components/Pages/PlannerPage.vue')

const SecurityPage = () => import('../components/Pages/SecurityPage.vue')

const EditWorkoutPage = () => import('../components/Pages/EditWorkoutPage.vue')

const HealthIssuesPage = () => import('../components/Pages/HealthIssuesPage.vue');

const SingleWorkoutPage = () => import('../components/Pages/SingleWorkoutPage.vue')

const SingleActivityPage = () => import('../components/Pages/SingleActivityPage.vue')

const SingleProjectPage = () => import('../components/Pages/SingleProjectPage.vue');

const SinglePlannedActivityPage = () => import('../components/Pages/SinglePlannedActivityPage.vue')

const WorkoutSessionsPage = () => import('../components/Pages/WorkoutSessionsPage.vue')

const PlacesPage = () => import('../components/Pages/PlacesPage.vue')

const CreatorPage = () => import('../components/Pages/CreatorPage.vue')

const NotesPage = () => import('../components/Pages/NotesPage.vue')

const BusinessIdeasPage = () => import('../components/Pages/BusinessIdeasPage.vue');

const ManagingStressPage = () => import('../components/Pages/ManagingStress.vue');

const CreateGoalPage = () => import('../components/Pages/Goals/Create.vue');

const ValuesPage = () => import('../components/Pages/ValuesPage.vue');

const BeliefsPage = () => import('../components/Pages/BeliefsPage.vue');

const InterestsPage = () => import('../components/Pages/InterestsPage.vue');

const MissionPage = () => import('../components/Pages/MissionPage.vue');

const PurposePage = () => import('../components/Pages/PurposePage.vue');

const ResolutionsPage = () => import('../components/Pages/ResolutionsPage.vue');

const VisionPage = () => import('../components/Pages/VisionPage.vue');

const MoviesPage = () => import('../components/Pages/MoviesPage.vue');

const ChaptersPage = () => import('../components/Pages/Chapters/All.vue');

const ChapterDetailsPage = () => import('../components/Pages/Chapters/Single.vue');

const DrugDetailsPage = () => import('../components/Pages/Drugs/Single.vue');

const MusicPage = () => import('../components/Pages/MusicPage.vue');

const QuotesPage = () => import('../components/Pages/QuotesPage.vue');

const QuestionsPage = () => import('../components/Pages/QuestionsPage.vue');

const EthosPage = () => import('../components/Pages/EthosPage.vue');

const PeoplePage = () => import('../components/Pages/PeoplePage.vue');

const MyGoalsPage = () => import('../components/Pages/Goals/MyGoals.vue');

const SingleGoalPage = () => import('../components/Pages/Goals/Single.vue');

const SingleGoalNoJourneePage = () => import('../components/Pages/Goals/SingleNoJournee.vue');

const WebsitesPage = () => import('../components/Pages/WebsitesPage.vue');

const NewJourneePage = () => import('../components/Pages/Journees/NewJournee.vue');

const SingleJourneePage = () => import('../components/Pages/Journees/Single.vue');

const MyJourneesPage = () => import('../components/Pages/Journees/MyJournees.vue');

const RitualsPage = () => import('../components/Pages/RitualsPage.vue');

const SkillsPage = () => import('../components/Pages/SkillsPage.vue');

const DrugsPage = () => import('../components/Pages/DrugsPage.vue');

const ProgramSubscribePage = () => import('../components/Pages/ProgramSubscribePage.vue');

const JourneeSummaryReport = () => import('../components/Pages/Reports/JourneeSummary.vue');

const GoalTimeLineReport = () => import('../components/Pages/Reports/GoalTimeLine.vue');

const LogWorkoutSessionPage = () => import('../components/Pages/LogWorkoutSessionPage.vue');

const DailyPlannerPage = () => import('../components/Pages/DailyPlannerPage.vue');

const AdminPage = () => import('../components/Pages/AdminPage.vue');

const AdminUsersPage = () => import('../components/Pages/Admin/Users.vue');

const routes = [
    {
        path:"/",
        component:DefaultPageLayout,
        children: [
            {
                name: "LandingPage",
                path:"",
                component: LandingPage,
                meta: { title: 'Journee - Landing Page', requiresAuth: false}
            }
        ]
    },
    {
        path:"/sign-up/:programCode?",
        component:DefaultPageLayout,
        children: [
            {
                name: "SignUpPage",
                path:"",
                component: SignUpPage,
                meta: { title: 'Journee - Sign Up', requiresAuth: false}
            }
        ]
    },
    {
        path:"/explore",
        component:DefaultPageLayout,
        children: [
            {
                name: "ExplorePage",
                path:"",
                component: ExplorePage,
                meta: { title: 'Explore', requiresAuth: false}
            }
        ]
    },
    {
        path:"/creator",
        component:DefaultPageLayout,
        children: [
            {
                name: "CreatorPage",
                path:"",
                component: CreatorPage,
                meta: { title: 'Creator', requiresAuth: false}
            }
        ]   
    },
    {
        path:"/contact",
        component:DefaultPageLayout,
        children: [
            {
                name: "ContactPage",
                path:"",
                component: ContactPage,
                meta: { title: 'Contact', requiresAuth: false}
            }
        ]
    },
    {
        path:"/overview",
        component:DefaultPageLayout,
        children: [
            {
                name: "OverviewPage",
                path:"",
                component: OverviewPage,
                meta: { title: 'Overview', requiresAuth: false}
            }
        ]
    },
    {
        path:"/about",
        component:DefaultPageLayout,
        children: [
            {
                name: "AboutUsPage",
                path:"",
                component: AboutUsPage,
                meta: { title: 'About Us', requiresAuth: false}
            }
        ]
    },
    {
        path:"/subscribe/:programCode",
        component:DefaultPageLayout,
        children: [
            {
                name: "ProgramSubscribePage",
                path:"",
                component: ProgramSubscribePage,
                meta: { title: 'Subscribe', requiresAuth: false}
            }
        ]
    },
    {
        path:"/categories",
        component:UserPageLayout,
        children: [
            {
                name: "UserJourneeCategories",
                path:"",
                component: UserJourneeCategories,
                meta: { title: 'Journee - Categories', requiresAuth: true}
            }
        ]
    },
    {
        path:"/forgot-password",
        component:DefaultPageLayout,
        children: [
            {
                name: "ForgotPasswordPage",
                path:"",
                component: ForgotPasswordPage,
                meta: { title: 'Journee - Forgot Password', requiresAuth: false}
            }
        ]
    },
    {
        path:"/security",
        component:DefaultPageLayout,
        children: [
            {
                name: "SecurityPage",
                path:"",
                component: SecurityPage,
                meta: { title: 'Journee - Security', requiresAuth: true}
            }
        ]
    },
    {
        path:"/terms",
        component:DefaultPageLayout,
        children: [
            {
                name: "TermsOfServicePage",
                path:"",
                component: TermsOfServicePage,
                meta: { title: 'Journee - Terms of Service', requiresAuth: false}
            }
        ]
    },
    {
        path:"/pricing",
        component:DefaultPageLayout,
        children: [
            {
                name: "PricingPage",
                path:"",
                component: PricingPage,
                meta: { title: 'Journee - Pricing', requiresAuth: false}
            }
        ]
    },
    {
        path:"/onboarding",
        component:DefaultPageLayout,
        children: [
            {
                name: "OnboardingPage",
                path:"",
                component: OnboardingPage,
                meta: { title: 'Journee - New User Onboarding', requiresAuth: true}
            }
        ]
    },
    {
        path:"/privacy",
        component:DefaultPageLayout,
        children: [
            {
                name: "PrivacyPolicyPage",
                path:"",
                component: PrivacyPolicyPage,
                meta: { title: 'Journee - Privacy Policy', requiresAuth: false}
            }
        ]
    },
    {
        path:"/login",
        component:DefaultPageLayout,
        children: [
            {
                name: "LoginPage",
                path:"",
                component: LoginPage,
                meta: { title: 'Journee - Login', requiresAuth: false}
            }
        ]
    },
    {
        path:"/admin",
        component:AdminPageLayout,
        children: [
            {
                name: "AdminPage",
                path:"",
                component: AdminPage,
                meta: { title: 'Journee - Admin', requiresAuth: true}
            }
        ]
    },
    {
        path:"/admin/users",
        component:AdminPageLayout,
        children: [
            {
                name: "AdminUsersPage",
                path:"",
                component: AdminUsersPage,
                meta: { title: 'Journee - Users', requiresAuth: true}
            }
        ]
    },
    {
        path:"/home",
        component:UserPageLayout,
        children: [
            {
                name: "UserHomePage",
                path:"",
                component: UserHomePage,
                meta: { title: 'Journee - Home', requiresAuth: true}
            }
        ]
    },
    {
        path:"/boards",
        component:UserPageLayout,
        children: [
            {
                name: "UserBoardsPage",
                path:"",
                component: UserBoardsPage,
                meta: { title: 'Journee - Boards', requiresAuth: true}
            }
        ]
    },
    {
        path:"/journal",
        component:UserPageLayout,
        children: [
            {
                name: "JournalPage",
                path:"",
                component: JournalPage,
                meta: { title: 'Journee - Journal', requiresAuth: true}
            }
        ]
    },
    {
        path:"/data",
        component:UserPageLayout,
        children: [
            {
                name: "UserDataTypesPage",
                path:"",
                component: UserDataTypesPage,
                meta: { title: 'Journee - Manage Data Types', requiresAuth: true}
            }
        ]
    },
    {
        path:"/devices",
        component:UserPageLayout,
        children: [
            {
                name: "UserDevicesPage",
                path:"",
                component: UserDevicesPage,
                meta: { title: 'Journee - Manage Devices', requiresAuth: true}
            }
        ]
    },
    {
        path:"/programs",
        component:UserPageLayout,
        children: [
            {
                name: "ProgramsPage",
                path:"",
                component: ProgramsPage,
                meta: { title: 'Journee - Manage Programs', requiresAuth: true}
            }
        ]
    },
    {
        path:"/chapters",
        component:UserPageLayout,
        children: [
            {
                name: "ChaptersPage",
                path:"",
                component: ChaptersPage,
                meta: { title: 'Journee - My Chapters', requiresAuth: true}
            }
        ]
    },
    {
        path:"/shared-pages",
        component:UserPageLayout,
        children: [
            {
                name: "UsersSharedPages",
                path:"",
                component: UsersSharedPages,
                meta: { title: 'Journee - My Shared Pages', requiresAuth: true}
            }
        ]
    },
    {
        path:"/chapter/:id",
        component:UserPageLayout,
        children: [
            {
                name: "ChapterDetailsPage",
                path:"",
                component: ChapterDetailsPage,
                meta: { title: 'Journee - Chapter Details', requiresAuth: true}
            }
        ]
    },
    {
        path:"/report/journee-summary",
        component:UserPageLayout,
        children: [
            {
                name: "JourneeSummaryReport",
                path:"",
                component: JourneeSummaryReport,
                meta: { title: 'Journee - Summary Report', requiresAuth: true}
            }
        ]
    },
    {
        path:"/report/goal-timeline",
        component:UserPageLayout,
        children: [
            {
                name: "GoalTimeLineReport",
                path:"",
                component: GoalTimeLineReport,
                meta: { title: 'Journee - Goal Timeline', requiresAuth: true}
            }
        ]
    },
    {
        path:"/drug/:id",
        component:UserPageLayout,
        children: [
            {
                name: "DrugDetailsPage",
                path:"",
                component: DrugDetailsPage,
                meta: { title: 'Journee - Drug Details', requiresAuth: true}
            }
        ]
    },
    {
        path:"/data/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleUserDataTypePage",
                path:"",
                component: SingleUserDataTypePage,
                meta: { title: 'Journee - Single Data Type', requiresAuth: true}
            }
        ]
    },
    {
        path:"/program/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleProgramPage",
                path:"",
                component: SingleProgramPage,
                meta: { title: 'Journee - Single Program', requiresAuth: true}
            }
        ]
    },
    {
        path:"/a/:id",
        component:DefaultPageLayout,
        children: [
            {
                name: "SharedPage",
                path:"",
                component: SharedPage,
                meta: { title: 'Journee - Shared Page', requiresAuth: false}
            }
        ]
    },
    {
        path:"/planner",
        component:UserPageLayout,
        children: [
            {
                name: "PlannerPage",
                path:"",
                component: PlannerPage,
                meta: { title: 'Journee - Planner', requiresAuth: true}
            }
        ]
    },
    {
        path:"/daily-planner/:id?",
        component:UserPageLayout,
        children: [
            {
                name: "DailyPlannerPage",
                path:"",
                component: DailyPlannerPage,
                meta: { title: 'Journee - Daily Planner', requiresAuth: true}
            }
        ]
    },
    {
        path:"/projects",
        component:UserPageLayout,
        children: [
            {
                name: "ProjectsPage",
                path:"",
                component: ProjectsPage,
                meta: { title: 'Journee - My Projects', requiresAuth: true}
            }
        ]
    },
    {
        path:"/how-journee-works",
        component:UserPageLayout,
        children: [
            {
                name: "HowJourneeWorksPage",
                path:"",
                component: HowJourneeWorksPage,
                meta: { title: 'Journee - How it works', requiresAuth: true}
            }
        ]
    },
    {
        path:"/calendar",
        component:UserPageLayout,
        children: [
            {
                name: "CalendarPage",
                path:"",
                component: CalendarPage,
                meta: { title: 'Journee - Calendar', requiresAuth: true}
            }
        ]
    },
    {
        path:"/events",
        component:UserPageLayout,
        children: [
            {
                name: "EventsPage",
                path:"",
                component: EventsPage,
                meta: { title: 'Journee - Events', requiresAuth: true}
            }
        ]
    },
    {
        path:"/activities",
        component:UserPageLayout,
        children: [
            {
                name: "ActivitiesPage",
                path:"",
                component: ActivitiesPage,
                meta: { title: 'Journee - Activities', requiresAuth: true}
            }
        ]
    },
    {
        path:"/moods",
        component:UserPageLayout,
        children: [
            {
                name: "MoodsPage",
                path:"",
                component: MoodsPage,
                meta: { title: 'Journee - Moods', requiresAuth: true}
            }
        ]
    },
    {
        path:"/life-plans",
        component:UserPageLayout,
        children: [
            {
                name: "LifePlanningPage",
                path:"",
                component: LifePlanningPage,
                meta: { title: 'Journee - Life Planning', requiresAuth: true}
            }
        ]
    },
    {
        path:"/life-plans/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleLifePlanPage",
                path:"",
                component: SingleLifePlanPage,
                meta: { title: 'Journee - Single Life Plan', requiresAuth: true}
            }
        ]
    },
    {
        path:"/sleep",
        component:UserPageLayout,
        children: [
            {
                name: "SleepPage",
                path:"",
                component: SleepPage,
                meta: { title: 'Journee - Sleep', requiresAuth: true}
            }
        ]
    },
    {
        path:"/business-ideas",
        component:UserPageLayout,
        children: [
            {
                name: "BusinessIdeasPage",
                path:"",
                component: BusinessIdeasPage,
                meta: { title: 'Journee - Business Ideas', requiresAuth: true}
            }
        ]
    },
    {
        path:"/health-issues",
        component:UserPageLayout,
        children: [
            {
                name: "HealthIssuesPage",
                path:"",
                component: HealthIssuesPage,
                meta: { title: 'Journee - Health Issues', requiresAuth: true}
            }
        ]
    },
    {
        path:"/movies",
        component:UserPageLayout,
        children: [
            {
                name: "MoviesPage",
                path:"",
                component: MoviesPage,
                meta: { title: 'Journee - Movies', requiresAuth: true}
            }
        ]
    },
    {
        path:"/quotes",
        component:UserPageLayout,
        children: [
            {
                name: "QuotesPage",
                path:"",
                component: QuotesPage,
                meta: { title: 'Journee - Quotes', requiresAuth: true}
            }
        ]
    },
    {
        path:"/questions",
        component:UserPageLayout,
        children: [
            {
                name: "QuestionsPage",
                path:"",
                component: QuestionsPage,
                meta: { title: 'Journee - Questions', requiresAuth: true}
            }
        ]
    },
    {
        path:"/account",
        component:UserPageLayout,
        children: [
            {
                name: "AccountSettingsPage",
                path:"",
                component: AccountSettingsPage,
                meta: { title: 'Journee - Account Settings', requiresAuth: true}
            }
        ]
    },
    {
        path:"/exercises",
        component:UserPageLayout,
        children: [
            {
                name: "ExercisesPage",
                path:"",
                component: ExercisesPage,
                meta: { title: 'My Exercises', requiresAuth: true}
            }
        ]
    },
    {
        path:"/workouts/:id/edit",
        component:UserPageLayout,
        children: [
            {
                name: "EditWorkoutPage",
                path:"",
                component: EditWorkoutPage,
                meta: { title: 'Workout AA', requiresAuth: true}
            }
        ]
    },
    {
        path:"/workouts/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleWorkoutPage",
                path:"",
                component: SingleWorkoutPage,
                meta: { title: 'Single Workout Page', requiresAuth: true}
            }
        ]
    },
    {
        path:"/activity/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleActivityPage",
                path:"",
                component: SingleActivityPage,
                meta: { title: 'Activity', requiresAuth: true}
            }
        ]
    },
    {
        path:"/project/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleProjectPage",
                path:"",
                component: SingleProjectPage,
                meta: { title: 'Project', requiresAuth: true}
            }
        ]
    },
    {
        path:"/plannedactivity/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SinglePlannedActivityPage",
                path:"",
                component: SinglePlannedActivityPage,
                meta: { title: 'Planned Activity', requiresAuth: true}
            }
        ]
    },
    {
        path:"/workout-session/:id",
        component:UserPageLayout,
        children: [
            {
                name: "LogWorkoutSessionPage",
                path:"",
                component: LogWorkoutSessionPage,
                meta: { title: 'Log Workout Page', requiresAuth: true}
            }
        ]
    },
    {
        path:"/workout-sessions",
        component:UserPageLayout,
        children: [
            {
                name: "WorkoutSessionsPage",
                path:"",
                component: WorkoutSessionsPage,
                meta: { title: 'View Workout Sessions', requiresAuth: true}
            }
        ]
    },
    {
        path:"/workouts",
        component:UserPageLayout,
        children: [
            {
                name: "WorkoutsPage",
                path:"",
                component: WorkoutsPage,
                meta: { title: 'My Workouts', requiresAuth: true}
            }
        ]
    },
    {
        path:"/rituals",
        component:UserPageLayout,
        children: [
            {
                name: "RitualsPage",
                path:"",
                component:RitualsPage,
                meta: { title: 'My Rituals', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/books",
        component:UserPageLayout,
        children: [
            {
                name: "BooksPage",
                path:"",
                component:BooksPage,
                meta: { title: 'My Books', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/reminders",
        component:UserPageLayout,
        children: [
            {
                name: "RemindersPage",
                path:"",
                component:RemindersPage,
                meta: { title: 'My Reminders', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/skills",
        component:UserPageLayout,
        children: [
            {
                name: "SkillsPage",
                path:"",
                component:SkillsPage,
                meta: { title: 'My Skills', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/drugs",
        component:UserPageLayout,
        children: [
            {
                name: "DrugsPage",
                path:"",
                component:DrugsPage,
                meta: { title: 'Drugs', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/places",
        component:UserPageLayout,
        children: [
            {
                name: "PlacesPage",
                path:"",
                component:PlacesPage,
                meta: { title: 'My Places', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/notes",
        component:UserPageLayout,
        children: [
            {
                name: "NotesPage",
                path:"",
                component:NotesPage,
                meta: { title: 'My Notes', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/setting-goals",
        component:UserPageLayout,
        children: [
            {
                name: "SettingGoalsPage",
                path:"",
                component:SettingGoalsPage,
                meta: { title: 'Setting Goals Page', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/managing-stress",
        component:UserPageLayout,
        children: [
            {
                name: "ManagingStressPage",
                path:"",
                component:ManagingStressPage,
                meta: { title: 'Managing Stress Page', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/mission",
        component:UserPageLayout,
        children: [
            {
                name: "MissionPage",
                path:"",
                component:MissionPage,
                meta: { title: 'My Mission', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/purpose",
        component:UserPageLayout,
        children: [
            {
                name: "PurposePage",
                path:"",
                component:PurposePage,
                meta: { title: 'My Purpose', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/vision",
        component:UserPageLayout,
        children: [
            {
                name: "VisionPage",
                path:"",
                component:VisionPage,
                meta: { title: 'My Vision', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/my-goals",
        component:UserPageLayout,
        children: [
            {
                name: "MyGoalsPage",
                path:"",
                component: MyGoalsPage,
                meta: { title: 'My Goals', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/people",
        component:UserPageLayout,
        children: [
            {
                name: "PeoplePage",
                path:"",
                component: PeoplePage,
                meta: { title: 'People', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/journees",
        component:UserPageLayout,
        children: [
            {
                name: "MyJourneesPage",
                path:"",
                component: MyJourneesPage,
                meta: { title: 'My Journees', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/journees/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleJourneePage",
                path:"",
                component: SingleJourneePage,
                meta: { title: 'This Journee', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/goal/:id",
        component:UserPageLayout,
        children: [
            {
                name: "SingleGoalPage",
                path:"",
                component: SingleGoalPage,
                meta: { title: 'My Goal', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/new-journee",
        component:UserPageLayout,
        children: [
            {
                name: "NewJourneePage",
                path:"",
                component: NewJourneePage,
                meta: { title: 'New Journee', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/new-goal",
        component:UserPageLayout,
        children: [
            {
                name: "CreateGoalPage",
                path:"",
                component:CreateGoalPage,
                meta: { title: 'New Goal', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/values",
        component:UserPageLayout,
        children: [
            {
                name: "ValuesPage",
                path:"",
                component:ValuesPage,
                meta: { title: 'My Values', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/resolutions",
        component:UserPageLayout,
        children: [
            {
                name: "ResolutionsPage",
                path:"",
                component: ResolutionsPage,
                meta: { title: 'My Resolutions', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/beliefs",
        component:UserPageLayout,
        children: [
            {
                name: "BeliefsPage",
                path:"",
                component:BeliefsPage,
                meta: { title: 'My Beliefs', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/interests",
        component:UserPageLayout,
        children: [
            {
                name: "InterestsPage",
                path:"",
                component: InterestsPage,
                meta: { title: 'My Interests', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/ethos",
        component:UserPageLayout,
        children: [
            {
                name: "EthosPage",
                path:"",
                component: EthosPage,
                meta: { title: 'My Ethos', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/websites",
        component:UserPageLayout,
        children: [
            {
                name: "WebsitesPage",
                path:"",
                component: WebsitesPage,
                meta: { title: 'My Websites', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/music",
        component:UserPageLayout,
        children: [
            {
                name: "MusicPage",
                path:"",
                component: MusicPage,
                meta: { title: 'My Music', requiresAuth: true} // <- I would to use this one
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

const maxRequests = 5;
let numRequests = 0;

router.beforeEach((to, from, next) => {


    document.title = to.meta.title;

    if(to.meta.requiresAuth) {

      if(store.getters['auth/authenticated'] == false) {
          next("/login");
      }

      if(store.getters['auth/authenticated']) {

          if(!store.getters['store/onboarded'] && stripTrailingSlash(to.path) !== '/onboarding') {
              next('/onboarding');
          }

          if(!store.getters['store/board'] && stripTrailingSlash(to.path) !== '/boards' && stripTrailingSlash(to.path) !== '/onboarding') {
            next('/boards');
          }
          
      }
    }

    if(store.getters['auth/authenticated'] == true && stripTrailingSlash(to.path) == '/login') {
      next("/home");
    }

    if(store.getters['auth/authenticated'] == true && stripTrailingSlash(to.path) == '') {
      next("/home");
    }

    if(store.getters['auth/authenticated'] == false && stripTrailingSlash(to.path) == '') {
      next();
    }

    next();
    
});

export default router