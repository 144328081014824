export default {

  data() {
    return {
      monthNames:[ "Jan", "Feb", "Mar", "Apl", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
      dayNames: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    }
  },
  methods: {
    getOrdinalFromDay(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    },
    fmtDate( date,includeTime = true ) {

      let DayOfWeek = this.dayNames[date.getDay()];
      let MonthName = this.monthNames[date.getMonth()];
      let OrdinalDay = date.getDate() + this.getOrdinalFromDay(date.getDate());
      let Year = date.getFullYear();

      let TimeOfDay = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

      let returnStr = DayOfWeek + ", " + MonthName + " " + OrdinalDay + " " + Year;

      if(includeTime) {
        return returnStr + " @ " + TimeOfDay;
      }

      return returnStr;
    },
    fmtTime( date ) {

      let TimeOfDay = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

      return TimeOfDay;
    },
  }
  // etc.
}